import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import PhoneForwarded from '@material-ui/icons/PhoneForwarded';
import FilterListIcon from '@material-ui/icons/FilterList';
import firebase from '../firebase';
import useForceUpdate from 'use-force-update';
import TextField from '@material-ui/core/TextField';
import Download from '../Download';

//function createData(name, calories, fat, carbs, protein) {
// return { name, calories, fat, carbs, protein };
//}


function createData(id, code, material, valor, operacion, name, fecha, contacto, username, phone, status) {
  return { id, code, material, operacion, valor, name, fecha, contacto, username, phone, status };
}


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const columns = [
  { id: 'id', label: 'id', minWidth: 30, align: 'left' },
  { id: 'code', label: 'Codigo', minWidth: 30, align: 'left' },
  { id: 'material', label: 'Material', minWidth: 50 },
  { id: 'valor', label: 'Material', minWidth: 30 },
  { id: 'operacion', label: 'Retiro / Entrega', minWidth: 50 },
  {
    id: 'name',
    label: 'Dirección',
    minWidth: 30,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'fecha',
    label: 'Fecha',
    minWidth: 30,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'contacto',
    label: 'contacto',
    minWidth: 30,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'username',
    label: 'nombre',
    minWidth: 30,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'phone',
    label: 'Número de contácto',
    minWidth: 30,
    align: 'right',
    format: value => value.toLocaleString(),
  },
  {
    id: 'status',
    label: 'status',
    minWidth: 30,
    align: 'right',
    format: value => value.toLocaleString(),
  },
];

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'code', numeric: false, disablePadding: true, label: 'Codigo' },
  { id: 'material', numeric: false, disablePadding: true, label: 'Material' },
  { id: 'valor', numeric: false, disablePadding: true, label: 'Valor' },
  { id: 'operacion', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Dirección' },
  { id: 'fecha', numeric: false, disablePadding: false, label: 'Fecha' },
  { id: 'contacto', numeric: false, disablePadding: false, label: 'Contacto' },
  { id: 'username', numeric: false, disablePadding: false, label: 'Nombre' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Telefono' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;


  const Botones = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-4" style={{ paddingRight: "20px" }}>
            <Tooltip title="Marcar" onClick={props.handleStatus}>
              <IconButton aria-label="marcar">
                <PhoneForwarded />
              </IconButton>
            </Tooltip>
          </div>
          <div className="col-md-4">
            <Tooltip title="Delete" onClick={props.handleDelete}>
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
              Solicitudes
          </Typography>
          )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Botones />
        ) : (
            <React.Fragment>
              <Download orders = {props.orders}/>
            {/*<Tooltip title="Filter list">
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
        </Tooltip>*/}
            </React.Fragment>
          )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const { orders } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('code');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [full, setFull] = React.useState(false);
  //const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const forceUpdate = useForceUpdate();
  const rows = [];
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function handleChangeFull(event) {
    let cant;
    if(event.target.checked){
      if(rows.length < 2000){
        cant = rows.length;
      }else{
        cant = 2000;
      }
      setRowsPerPage(+cant);
      setPage(0);
    }else{
      setRowsPerPage(+5);
      setPage(0);
    }
    setFull(event.target.checked);
  }

  function handleDelete() {
    const refOrders = firebase.firestore().collection("Orders");
    selected.map(item =>{
      refOrders.doc(item).delete().then((res) => {
        console.log("se eliminó el registro");
        setSelected([]);
      }).catch(err => {
        console.log("no se pudo eliminar el registro");
      });
      props.actualizar();
    })
  }

  function handleStatus() {
    console.log(selected);
    const refOrders = firebase.firestore().collection("Orders");
    selected.map(item =>{
      refOrders.doc(item).update({ status: 'Contactado' }).then((res) => {
        console.log("se actualizó el registro");
        setSelected([]);
      }).catch(err => {
        console.log("no se pudo actualizar el registro");
      });
      props.actualizar();
    })
  }

  props.orders.map(item => {
    var tipo = (item.delivery) ? 'Retiro' : 'Entrega';
    var status = (item.status) ? item.status : "Pendiente";
    const fecha = new Intl.DateTimeFormat('en-US', {year: 'numeric', 
            day: '2-digit',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',}).format(item.date.toDate());
    var d = fecha;
    rows.push(createData(item.id, item.code,item.detalle.productName,item.detalle.subprofit, tipo, item.address,d, item.userEmail, item.userName, item.userPhone, status));
  });

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  var formatNumber = {
    separador: ".", // separador para los miles
    sepDecimal: ',', // separador para los decimales
    formatear: function (num) {
        num += '';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return this.simbol + splitLeft + splitRight;
    },
    new: function (num, simbol) {
        this.simbol = simbol || '';
        return this.formatear(num);
    }
  }

  function show(instruccion){
    var a = [];
    var count = 0;
    {stableSort(rows, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          if(instruccion === row.code){
            count = count+1;
          }
        })}

    return count;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} handleDelete={handleDelete} handleStatus={handleStatus} orders={props.orders}/>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.material}</TableCell>
                      <TableCell align="left">{formatNumber.new(parseInt(row.valor), "$")}</TableCell>
                      <TableCell align="left">{row.operacion}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.fecha}</TableCell>
                      <TableCell align="left">{row.contacto}</TableCell>
                      <TableCell align="left">{row.username}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                    </TableRow>
                  );

                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Página anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Siguiente página',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <FormControlLabel
        control={<Switch checked={full} onChange={handleChangeFull} />}
        label="2000 columnas"
      />
    </div>
  );
}
