import React from 'react';
import { relative } from 'path';
import Scrollchor from 'react-scrollchor';
import corfo from './corfo.png'
import facebook from './facebook.png'
import twitter from './twitter.png'
import instagram from './instagram.png'
var colors = ['#6A1B9A', '#76FF03', '#4527A0'];

var Footer = (props) => {
    return (
        <React.Fragment>
                    <span className="ir-arriba icon-arrow-up2">⌃</span>

        <Scrollchor to="inicio" className="nav-link">
        <span className="ir-arriba icon-arrow-up2">⌃</span>
        </Scrollchor>
        <div className="container-fluid" style={{ backgroundColor: "#eeeeee", minHeight: "200px" }}>
            <div className="container" style={{paddingTop:"40px"}}>
                <div className="row">
                    <div className="col col-md-6 col-sm-6 col-xs-12 ml-auto text-left" style={{paddingTop:'20px'}}>
                      <a href="https://corfo.cl"><img src={corfo} className="img-fluid" width="140px"></img></a>
                    </div>
                    <div className="col col-md-6 col-sm-6 col-xs-12 ml-auto text-left">
                      
                    </div>
                </div>
                <div className="row">
                    <div className="col col-md-6 col-sm-6 col-xs-12 ml-auto" style={{paddingTop:'10px'}}>
                        <ul className="list-inline p-2 enlaces">
                            <li className="list-inline-item"><a className="social-icon text-xs-center" target="_blank" href="/Somos">Nosotros</a></li>
                            <li className="list-inline-item"><a className="social-icon text-xs-center" target="_blank" href="/Contactanos">FAQ</a></li>
                            <li className="list-inline-item"><a className="social-icon text-xs-center" target="_blank" href="Terminos_y_Condiciones_UberGreen.pdf">Terminos y Condiciones</a></li>
                        </ul>
                    </div>
                    <div className="col col-md-6 col-sm-6 col-xs-12 mr-auto text-right" style={{paddingTop:'10px'}}>
                        <ul className="list-inline p-2">
                            <li className="list-inline-item"><a className="social-icon text-xs-center" target="_blank" href="https://twitter.com/ubergreencl"><img src={twitter} width="20px" className="img-fluid" alt="Responsive image"></img></a></li>
                            <li className="list-inline-item"><a className="social-icon text-xs-center" target="_blank" href="https://www.facebook.com/pg/ubergreencl/posts/?ref=page_internal"><img src={facebook}width="20px" className="img-fluid" alt="Responsive image"></img></a></li>
                            <li className="list-inline-item"><a className="social-icon text-xs-center" target="_blank" href="https://www.instagram.com/p/BxgDji0A-ey/?igshid=hb4z0egserqi"><img src={instagram} width="20px" className="img-fluid" alt="Responsive image"></img></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default Footer;